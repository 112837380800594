import { Component, OnInit, ViewEncapsulation, OnDestroy } from "@angular/core";
import * as d3 from "d3";
import { Subscription } from "rxjs";
import { ApiService } from "src/app/core-module/services/api.service";


@Component({
  selector: 'app-clo-graph',
  templateUrl: './clo-graph.component.html',
  styleUrls: ['./clo-graph.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CloGraphComponent implements OnInit, OnDestroy {
  title = "linechartDemo";

  inputXRange = [0, 120];
  inputYRange = [0, 100];

  data: any[] = [
    { hour: 0, power: 30 },
    { hour: 20, power: 55 },
    { hour: 50, power: 65 },
    { hour: 60, power: 45 },
    { hour: 90, power: 80 },
    { hour: 120, power: 100 },
  ];
  clonedData: any[] = [];
  focus;
  drag;

  public margin = { top: 50, right: 50, bottom: 50, left: 50 };
  public width: number;
  public height: number;
  public x: any;
  public y: any;
  public svg2: any;
  public line: d3.Line<[number, number]>; // this is line defination
  public area: d3.area<[number, number]>; // this is area defination
  public id = "lineChart";
  public color1 = "#00b36b";
  graphDisabled;
  graphType;
  subscription;
  successfullDrag: boolean = false;
  graphSub: Subscription;
  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.apiService.checkEnableGraph.subscribe((data) => {
      this.graphDisabled = data;
    });

    this.graphSub = this.apiService.getCloGraphData.subscribe((data) => {
      if (data != "") {
        if (data.graphPoints.length) {
          this.data = data.graphPoints;
          this.graphType = data.graphType;
          if (this.graphType == "Non-Interpolation") {
            const dataLeangth = this.data.length;
            var newData = [];
            var j = 0;
            for (var i = 0; i < dataLeangth; i++) {
              newData[j] = {
                hour: this.data[i].hour,
                power: this.data[i].power,
              };
              if (i + 1 < dataLeangth) {
                newData[j + 1] = {
                  hour: this.data[i + 1].hour,
                  power: this.data[i].power,
                };
              }
              j = j + 2;
            }
            this.data = newData;
          }
          this.clonedData = Object.assign([], this.data);
          const newdataLeangth = this.clonedData.length;
          if (this.clonedData[newdataLeangth - 1].hour < 120) {
            this.clonedData[newdataLeangth] = {
              hour: 120,
              power: this.clonedData[newdataLeangth - 1].power,
            };
          }
          this.buildSvg();
          this.addXandYAxis();
          this.drawLineAndPath();
          this.addDrag();
        }

        if (!data["graphPoints"].length) {
          d3.selectAll("#svg2 > *").remove();
        }
      }
    });
  }
  public buildSvg() {
    this.svg2 = d3.select("#svg2");

    this.width = 500 - this.margin.left - this.margin.right;
    this.height = 500 - this.margin.top - this.margin.bottom;
  }
  public addXandYAxis() {
    // range of data configuring
    this.x = d3.scaleLinear().range([0, this.width]);
    this.y = d3.scaleLinear().range([this.height, 0]);
    this.x.domain(this.inputXRange);
    this.y.domain(this.inputYRange);
  }

  public drawLineAndPath() {
    this.line = d3
      .line()
      .x((d: any) => this.x(d.hour))
      .y((d: any) => this.y(d.power));
    d3.selectAll("#svg2 > *").remove();
    this.area = d3
      .area()
      .x((d: any) => this.x(d.hour))
      .y0(this.height)
      .y1((d: any) => this.y(d.power));

    this.focus = this.svg2
      .append("g")
      .attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );
    //ploting Area
    this.focus
      .append("path")
      .datum(this.clonedData)
      .attr("fill", "#1ECBD0")
      .attr("fill-opacity", 0.3)
      .attr("stroke", "#00b36b")
      .attr("stroke-width", "1px")
      .attr("fill", "url('#gradient1')")
      .attr("d", this.area);

    //ploting circle
    this.focus
      .selectAll("circle")
      .data(this.clonedData)
      .enter()
      .append("circle")
      .attr("r", 5.0)
      .attr("cx", (d: any) => this.x(d.hour))
      .attr("cy", (d: any) => this.y(d.power))
      .style("cursor", "pointer")
      .style("fill", "#1ECBD0");

    const maxY = d3.max(this.clonedData, function (d) {
      return d.power;
    });
    // Add Gradient
    this.svg2
      .append("linearGradient")
      .attr("id", "gradient1")
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("x1", 0)
      .attr("y1", this.y(0))
      .attr("x2", 0)
      .attr("y2", this.y(maxY))
      .selectAll("stop")
      .data([
        {
          offset: "10%",
          color: "transparent",
        },
        {
          offset: "30%",
          color: "#00E487",
        },
        {
          offset: "100%",
          color: this.color1,
        },
      ])
      .enter()
      .append("stop")
      .attr("offset", function (d) {
        return d.offset;
      })
      .attr("stop-color", function (d) {
        return d.color;
      });

    this.focus
      .append("g")
      .attr("class", "axis axis--x")
      .attr("transform", "translate(0," + this.height + ")")
      .call(d3.axisBottom(this.x));
    // text label for the x axis
    this.svg2
      .append("text")
      .attr(
        "transform",
        "translate(" +
        (this.width / 2 + 40) +
        " ," +
        (this.height + this.margin.top + 40) +
        ")"
      )
      .style("text-anchor", "middle")
      .style("font", "16px Signify-Relative-Bold")
      .html("Working hours x 1000 h");

    // Configure the Y Axis
    this.focus
      .append("g")
      .attr("class", "axis axis--y")
      .call(d3.axisLeft(this.y));

    // text label for the y axis
    this.svg2
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - this.margin.left + 65)
      .attr("x", 0 - this.height / 2 -50)
      .style("text-anchor", "middle")
      .style("font", "16px Signify-Relative-Bold")
      .text("Output level ( % )");

    // add the X gridlines
    this.focus
      .append("g")
      // .attr("class", "grid")
      .attr("transform", "translate(0," + this.height + ")")
      .call(this.make_x_gridlines().tickSize(-this.height).tickFormat(""));

    // add the Y gridlines
    this.focus
      .append("g")
      // .attr("class", "grid")
      .call(this.make_y_gridlines().tickSize(-this.width).tickFormat(""));
  }

  public addDrag() {
    return;
    const _this = this;
    let selection = this.focus.selectAll("circle").call(
      d3
        .drag()
        .on("start", function (event, d) {
          const control = _this.dragstarted();
        })
        .on("drag", function (event, d) {
          _this.successfullDrag = false;
          const control = _this.dragstarted();
          const n = selection.nodes();
          const i = n.indexOf(this);
          const m = d3.select(n[i]).node();
          d.hour = control.x.invert(event.x);
          d.power = control.y.invert(event.y);
          let cloNodeCnt = n.length;

        
          if (i === 0) {
            let obji1 = n[i + 1].__data__;
            if(d.power>100){
              d.power=100;
            }
            if(d.power<0){
              d.power=0;
            }
             if(d.hour<0 || d.hour>0){
              d.hour=0;
            }
            // if(d.hour>120){
            //   d.hour=120;
            // }
            if (d.hour > obji1.hour)
            return;
          }
          else if (i === cloNodeCnt - 1) {
            if(d.power>100){
              d.power=100;
            }
            if(d.power<0){
              d.power=0;
            }
            if(d.hour<0){
              d.hour=0;
            }
            if(d.hour>120){
              d.hour=120;
            }
            
              //return;
            /*  let obji1 = n[i-1].__data__;
             if(d.hour<obji1.hour)
             d.hour = obji1.hour+1; */
          }
          else {
            let obji1 = n[i + 1].__data__;
            let obji2 = n[i - 1].__data__;
            if(d.power>100){
              d.power=100;
            }
            if(d.power<0){
              d.power=0;
            }
            if(d.hour<0){
              d.hour=0;
            }
            if(d.hour>120){
              d.hour=120;
            }
            
            if (d.hour > obji1.hour)
              d.hour = obji1.hour - 1;
            else if (d.hour < obji2.hour)
              d.hour = obji2.hour + 1;
          }
          _this.successfullDrag = true;
          d3.select(m).attr("cx", control.x(d.hour)).attr("cy", control.y(d.power));
          control.focus.select("path").attr("d", control.area);
        })
        .on("end", this.dragended.bind(this))
    );
  }

  dragstarted() {
    return this;
  }
  public dragended(d) {
    if (!this.successfullDrag)
      return;

    var newArrayData = [];
    if (this.graphType == "Non-Interpolation") {
      var k = 0;
      for (var i = 0; i < this.data.length; i += 2) {
        newArrayData[k] = this.data[i];
        k++;
      }
    } else {
      newArrayData = this.data;
    }
    this.checkValidInputRange(this.data);
    this.apiService.updateGraphData(newArrayData);
  }

  // gridlines in x axis function
  make_x_gridlines() {
    return d3.axisBottom(this.x).ticks(10);
  }

  // gridlines in y axis function
  make_y_gridlines() {
    return d3.axisLeft(this.y).ticks(10);
  }

  checkValidInputRange(inputData) {
    this.apiService.sendGraphValidData("valid");
    inputData.forEach((content, key) => {
      if (key === 0 && !(content["hour"] === 0)) {
        this.data[key]["hour"] = 0;
      }
      if (
        content["hour"] < this.inputXRange[0] ||
        content["hour"] > this.inputXRange[1]
      ) {
        this.apiService.sendGraphValidData("invalid");
      }
      if (
        content["power"] < this.inputYRange[0] ||
        content["power"] > this.inputYRange[1]
      ) {
        this.apiService.sendGraphValidData("invalid");
      }
      if (
        content["hour"] < 0 ||
        content["power"] < 0 ||
        content["hour"] > 120 ||
        content["power"] > 100
      ) {
        alert("Invalid CLO values");
        if (
          content["hour"] < this.inputXRange[0] ||
          content["hour"] > this.inputXRange[1]
        ) {
          this.data[key]["hour"] = 0;
        }
        if (content["power"] < 0 || content["power"] > 100) {
          this.data[key]["power"] = 100;
        }
      }
    });
  }

  ngOnDestroy() {
    this.apiService.updateGraphData([]);
    this.data = [];
    this.clonedData = [];
    this.graphSub.unsubscribe();
  }

}
