import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";

import { CustomAuthService } from "../services/custom-auth.service";
import { catchError, concatMap } from "rxjs/operators";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authSrv: CustomAuthService) {}

  private excluded_urls = [
    "/oauth2/token",
    "authenticate/code",
    "authenticate/refresh",
    "assets/json",
    'localhost'
  ];
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isExcluded = this.excluded_urls.some((subUrl) =>
      request.url.includes(subUrl)
    );
    let isLoggedIn = this.authSrv.isUserLoggedIn();
    let token = this.authSrv.getToken();

    const addBearer = () => {
      let readDevice = JSON.parse(localStorage.getItem("reading"));
      if (isLoggedIn && token && !readDevice && !isExcluded) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    };

    const handleError = (error: HttpErrorResponse) => {
        if(error && error.status == 401 && token){
            this.authSrv.handleTokenExpiry();
        }
        return throwError(error);
    }

    addBearer();
    if (this.authSrv.isRefreshing && !isExcluded) {
      return this.authSrv.onRefreshing.pipe(
        concatMap(() => {
          addBearer();
          return next.handle(request).pipe(
            catchError(handleError)
          );
        })
      );
    } else {
      return next.handle(request).pipe(catchError(handleError));
    }
  }
}
