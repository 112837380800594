import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfigureDeviceModalInputErrorComponent } from "../configure-device-modal-input-error/configure-device-modal-input-error.component";
import { ConfigureDeviceModalInputErrorPositiveComponent } from "../configure-device-modal-input-error-positive/configure-device-modal-input-error-positive.component";
import { ReadDeviceMultiDriverComponent } from "../read-device-multi-driver/read-device-multi-driver.component";
import { ApiService } from "../core-module/services/api.service";
import { Router } from "@angular/router";
import { DataLayerService } from "../core-module/services/data-layer.service";
@Component({
  selector: "app-configure-device-modal-input",
  templateUrl: "./configure-device-modal-input.component.html",
  styleUrls: ["./configure-device-modal-input.component.scss"],
})
export class ConfigureDeviceModalInputComponent implements OnInit {
  boxSize: any = 24;
  ngModelval: any = [];
  constructor(
    public activemodal: NgbActiveModal,
    private modalService: NgbModal,
    private apiService: ApiService,
    public route: Router,
    private _dataLayerService: DataLayerService
  ) {}

  ngOnInit() {
    this.ngModelval["boxSize"] = this.boxSize = 24;
    this.apiService.sendMultiBoxSizeUser(this.boxSize);
  }
  onNext() {
    let configuration = JSON.parse(localStorage.getItem("configurationData"));
    let dataSet = [];
    for (var i = 0; i < configuration["selectedFeaturesInfo"].length; i++) {
      dataSet.push({
        name: configuration["selectedFeaturesInfo"][i].slice(0, -1),
        action: "included",
      });
    }
    this._dataLayerService.setSelectedDeviceInfo({
      event: "saving",
      savingType: "configBox",
      "12NC": configuration.device12nc,
      "devices":this.boxSize,
      features: dataSet,
    });
    this.apiService.sendShowNextSuccessBtnMulti(false);
    const modalRef = this.modalService.open(ReadDeviceMultiDriverComponent, {
      windowClass: "select-feature-modal",
      backdropClass: "customBackdrop",
    });
  }
  onCancel() {
    localStorage.setItem("reading", JSON.stringify(false));
    this.route.navigate(["/featureLanding"]);
  }
  numberOfDevices(value) {
    this.boxSize = value;

    this.ngModelval["boxSize"] = this.boxSize;
    this.apiService.sendMultiBoxSizeUser(this.boxSize);
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
