import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  profileOption=false;
  SSOUrl;
  socialLinks:any=[];
  copyRightYear:string="2023";
  constructor(private router: Router) { }

  ngOnInit() {
    this.socialLinks.linkedin="https://www.linkedin.com/company/signifycompany/";
    this.socialLinks.twitter="https://twitter.com/signifycompany";
    this.socialLinks.instagram="https://www.instagram.com/Signifycompany";
    this.socialLinks.pinterest="https://www.pinterest.com/signifycompany/";
    this.socialLinks.youtube="https://www.youtube.com/c/SignifyCompany";

    
    this.copyRightYear = new Date().getFullYear().toString();
  }
  socialLink(link){
        window.open(link, '_blank');

  }
  navigateToAbout() {
    this.router.navigate(["/about"]);
  }

}
