import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./shared-module/components/header/header.component";
import { FooterComponent } from "./shared-module/components/footer/footer.component";
import { HomeComponent } from "./shared-module/components/home-component/home.component";
import { FilterComponent } from "./shared-module/components/filter/filter.component";
import { DeviceComponent } from "./shared-module/components/device/device.component";
import { LandingPageComponent } from "./shared-module/components/landing-page/landing-page.component";
import { ApiService } from "./core-module/services/api.service";
import { NgxPaginationModule } from "ngx-pagination";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { JwtInterceptor } from "../app/core-module/guards/jwtInterceptors";
import { ErrorInterceptor } from "../app/core-module/guards/errorHandler";
import { SelectingDeviceModalComponent } from "./shared-module/components/selecting-device-modal/selecting-device-modal.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ConfigureDeviceModalComponent } from "./shared-module/components/configure-device-modal/configure-device-modal.component";
import { FeatureSupportedComponent } from "./shared-module/components/feature-supported/feature-supported.component";
import { Ng5SliderModule } from "ng5-slider";
import { FormlyModule } from "@ngx-formly/core";
import { SliderComponent } from "./shared-module/components/slider/slider.component";
import { AloSliderComponent } from "./shared-module/components/alo-slider/alo-slider.component";
import { PresetComponent } from "./shared-module/components/preset/preset.component";
import { SetFeatureLandingComponent } from "./shared-module/components/set-feature-landing/set-feature-landing.component";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";
import { GraphComponent } from "./shared-module/components/graph/graph.component";
import { ChartdataComponent } from "./shared-module/components/chartdata/chartdata.component";
import { PsuFeatureComponent } from "./shared-module/components/psu-feature/psu-feature.component";
import { LumInfoComponent } from "./shared-module/components/lum-info/lum-info.component";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { ZtvComponent } from "./shared-module/components/ztv/ztv.component";
import { StartuptimeComponent } from "./shared-module/components/startuptime/startuptime.component";
import { DatePipe } from "@angular/common";
import { TokenRefreshComponent } from "./token-refresh/token-refresh.component";
import { ReadDeviceComponent } from "./shared-module/components/read-device/read-device.component";
import { ReaderSelectionPopupComponent } from "./shared-module/components/reader-selection-popup/reader-selection-popup.component";
import { SupportedFeaturesComponent } from "./shared-module/components/supported-features/supported-features.component";
import { DtlComponent } from "./shared-module/components/dtl/dtl.component";
import { ReadDefaultValuesPopupComponent } from "./shared-module/components/read-default-values-popup/read-default-values-popup.component";
import { CorridorComponent } from "./shared-module/components/corridor/corridor.component";
import { CorridorGraphComponent } from "./shared-module/components/corridor-graph/corridor-graph.component";
import { DcemergencyComponent } from "./shared-module/components/dcemergency/dcemergency.component";
import { MinDimLevelComponent } from "./shared-module/components/min-dim-level/min-dim-level.component";
import { TouchDimComponent } from "./shared-module/components/touch-dim/touch-dim.component";
import { DtlGraphComponent } from "./shared-module/components/dtl-graph/dtl-graph.component";
import { DimmingInterfaceComponent } from "./shared-module/components/dimming-interface/dimming-interface.component";
import { ConverToNumberPipe } from "./shared-module/pipes/conver-to-number.pipe";
import { OnlynumberDirective } from "./shared-module/directive/onlynumber.directive";
import { ChartdataDynaDimmerComponent } from "./shared-module/components/chartdata-dyna/chartdata-dyna.component";

import { SampledynagraphComponent } from "./shared-module/components/sampledynagraph/sampledynagraph.component";
import { SamplegraphdynanontimeComponent } from "./shared-module/components/samplegraphdynanontime/samplegraphdynanontime.component";
import { FeatureNonSupportedComponent } from "./shared-module/components/feature-non-supported/feature-non-supported.component";
import { MidnightShiftCalculationComponent } from "./midnight-shift-calculation/midnight-shift-calculation.component";
import { FeatureFilePartialCompatibleModalComponent } from "./feature-file-partial-compatible-modal/feature-file-partial-compatible-modal.component";
import { DynadimmerGraphtoggleComponent } from "./dynadimmer-graphtoggle/dynadimmer-graphtoggle.component";
import { LineSwitchComponent } from "./shared-module/components/line-switch/line-switch.component";
import { ApmDimGraphComponent } from "./shared-module/components/apm-dim-graph/apm-dim-graph.component";
import { ApmDimComponent } from "./shared-module/components/apm-dim/apm-dim.component";
import { MtpComponent } from "./mtp/mtp.component";
import { MtpGraphComponent } from "./mtp-graph/mtp-graph.component";
import { ErrorComponent } from "./error/error.component";
import { CodedmainsComponent } from "./codedmains/codedmains.component";
import { DataLayerService } from "./core-module/services/data-layer.service";
import { WindowReferenceService } from "./core-module/services/window-reference.service";
import { ConfigureDeviceModalInputComponent } from "./configure-device-modal-input/configure-device-modal-input.component";
import { ConfigureDeviceModalInputErrorComponent } from "./configure-device-modal-input-error/configure-device-modal-input-error.component";
import { ConfigureDeviceModalInputErrorPositiveComponent } from "./configure-device-modal-input-error-positive/configure-device-modal-input-error-positive.component";
import { ReadDeviceMultiDriverComponent } from "./read-device-multi-driver/read-device-multi-driver.component";
import { ReadDeviceMultiDriverNextComponent } from "./read-device-multi-driver-next/read-device-multi-driver-next.component";
import { ReadDeviceMultiDriverSuccessComponent } from "./read-device-multi-driver-success/read-device-multi-driver-success.component";
import { ReadDeviceMultiDriverErrorComponent } from "./read-device-multi-driver-error/read-device-multi-driver-error.component";
import { CloComponent } from "./shared-module/components/clo/clo.component";
import { CloGraphComponent } from "./shared-module/components/clo-graph/clo-graph.component";
import { CloLiteComponent } from "./shared-module/components/clo-lite/clo-lite.component";
import { CloLiteGraphComponent } from "./shared-module/components/clo-lite-graph/clo-lite-graph.component";
import { OwpComponent } from "./shared-module/components/owp/owp.component";
import { SupportedFeaturesComponentMulti } from "./shared-module/components/supported-features-multi/supported-features.component-multi";
import { DaliMComponent } from "./shared-module/components/dali-m/dali-m.component";
import { AboutComponent } from "./about/about.component";
import { ReadDeviceSelectionPopupComponent } from "./shared-module/components/read-device-selection-popup/read-device-selection-popup.component";
import { ZtvoutputComponent } from "./ztvoutput/ztvoutput.component";
import { RsoComponent } from "./rso/rso.component";
import { LipComponent } from './lip/lip.component';
import { OwpPasswordComponent } from "./owp-password/owp-password.component";
import { FlextuneComponent } from './shared-module/components/flextune/flextune.component';
import { MultiConfigModeComponent } from './shared-module/components/multi-config-mode/multi-config-mode.component';
import { appInputError } from "./shared-module/directive/app-input-error.directive";
import { OAuthModule } from "angular-oauth2-oidc";
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    FilterComponent,
    DeviceComponent,
    LandingPageComponent,
    SelectingDeviceModalComponent,
    ConfigureDeviceModalComponent,
    FeatureSupportedComponent,
    SliderComponent,
    AloSliderComponent,
    PsuFeatureComponent,
    LumInfoComponent,
    PresetComponent,
    SetFeatureLandingComponent,
    GraphComponent,
    ChartdataComponent,
    ChartdataDynaDimmerComponent,
    ZtvComponent,
    StartuptimeComponent,
    TokenRefreshComponent,
    ReadDeviceComponent,
    ReaderSelectionPopupComponent,
    ReadDeviceSelectionPopupComponent,
    SupportedFeaturesComponent,
    SupportedFeaturesComponentMulti,
    DtlComponent,
    ReadDefaultValuesPopupComponent,
    CorridorComponent,
    CorridorGraphComponent,
    DcemergencyComponent,
    MinDimLevelComponent,
    TouchDimComponent,
    DtlGraphComponent,
    DimmingInterfaceComponent,
    ConverToNumberPipe,
    OnlynumberDirective,
    appInputError,
    SampledynagraphComponent,
    SamplegraphdynanontimeComponent,
    FeatureNonSupportedComponent,
    MidnightShiftCalculationComponent,
    FeatureFilePartialCompatibleModalComponent,
    DynadimmerGraphtoggleComponent,
    LineSwitchComponent,
    ApmDimGraphComponent,
    ApmDimComponent,
    MtpComponent,
    MtpGraphComponent,
    ErrorComponent,
    OwpPasswordComponent,
    CodedmainsComponent,
    ConfigureDeviceModalInputComponent,
    ConfigureDeviceModalInputErrorComponent,
    ConfigureDeviceModalInputErrorPositiveComponent,
    ReadDeviceMultiDriverComponent,
    ReadDeviceMultiDriverNextComponent,
    ReadDeviceMultiDriverSuccessComponent,
    ReadDeviceMultiDriverErrorComponent,
    CloComponent,
    CloGraphComponent,
    CloLiteComponent,
    CloLiteGraphComponent,
    OwpComponent,
    DaliMComponent,
    AboutComponent,
    ReadDeviceSelectionPopupComponent,
    ZtvoutputComponent,
    RsoComponent,
    LipComponent,
    FlextuneComponent,
    MultiConfigModeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgxPaginationModule,
    NgbModule,
    Ng5SliderModule,
    AutocompleteLibModule,
    OAuthModule.forRoot(),
    FormlyModule.forRoot({
      types: [
        { name: "slider", component: SliderComponent },
        { name: "object", component: AloSliderComponent },
        { name: "powerSupply", component: PsuFeatureComponent },
        { name: "LumInfoComponent", component: LumInfoComponent },
        { name: "ZtvComponent", component: ZtvComponent },
        { name: "StartuptimeComponent", component: StartuptimeComponent },
        { name: "DtlComponent", component: DtlComponent },
        { name: "CorridorComponent", component: CorridorComponent },
        { name: "LineSwitchComponent", component: LineSwitchComponent },
        { name: "MtpComponent", component: MtpComponent },
        { name: "AmpDimComponent", component: ApmDimComponent },
        { name: "DcemergencyComponent", component: DcemergencyComponent },
        { name: "MinDimLevelComponent", component: MinDimLevelComponent },
        { name: "TouchDimComponent", component: TouchDimComponent },
        { name: "DimmingComponent", component: DimmingInterfaceComponent },
        { name: "CodedmainsComponent", component: CodedmainsComponent },
        { name: "OwpComponent", component: OwpComponent },
        { name: "DaliMComponent", component: DaliMComponent },
        { name: "RsoComponent", component: RsoComponent },
        { name: "ZtvoutputComponent", component: ZtvoutputComponent },
        { name: "LipComponent", component: LipComponent },
        { name: "FlextuneComponent", component: FlextuneComponent },
        {name: 'MulticonfigComponent', component: MultiConfigModeComponent}
      ],
      validationMessages: [
        { name: "required", message: "This field is required" },
      ],
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  entryComponents: [
    ReadDeviceMultiDriverErrorComponent,
    ReadDeviceMultiDriverSuccessComponent,
    FeatureFilePartialCompatibleModalComponent,
    SelectingDeviceModalComponent,
    ReadDeviceMultiDriverComponent,
    ConfigureDeviceModalComponent,
    ReadDeviceComponent,
    ConfigureDeviceModalInputComponent,
    ConfigureDeviceModalInputErrorComponent,
    ReaderSelectionPopupComponent,
    ReadDefaultValuesPopupComponent,
    MidnightShiftCalculationComponent,
    ErrorComponent,
    OwpPasswordComponent,
    ReadDeviceMultiDriverNextComponent,
    ConfigureDeviceModalInputErrorPositiveComponent,
    ReadDeviceSelectionPopupComponent,
  ],
  providers: [
    DataLayerService,
    WindowReferenceService,
    ApiService,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
