import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-set-feature-landing',
  templateUrl: './set-feature-landing.component.html',
  styleUrls: ['./set-feature-landing.component.scss']
})
export class SetFeatureLandingComponent implements OnInit {
  constructor(private router: Router) {
    if(this.isPageRefresh() && localStorage.getItem("configurationData")==null){
      this.router.navigate(['/deviceList']);
    }
   }

  ngOnInit() {
    
  }

  private isPageRefresh() : boolean {
    return( ! this.router.navigated );
}

}
