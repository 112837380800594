import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomAuthService } from 'src/app/core-module/services/custom-auth.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  spinnerLoad;
  filterChekcedValue;

  constructor(private route: ActivatedRoute, private router: Router,  private authSrv: CustomAuthService) { }

  ngOnInit() {
   let authCode = this.route.snapshot.queryParamMap.get("code");
    if(authCode) {
      this.router.navigate(['/home'], {queryParamsHandling:'preserve'});
    }else{
      if(!this.authSrv.isUserLoggedIn()) this.router.navigate(['/home']);
    }
  }
  filterChekced(e){
    this.filterChekcedValue=e;
  }

  spinner(e) {
    this.spinnerLoad = e;
  }

  isLoggingIn(){
    return this.authSrv.IsLoggigIn();
  }
}
