
export const authoriser_domain = {
    'localhost': 'alhnxkfca',
    'dev': 'alhnxkfca',
    'tst': 'alhnxkfca',
    'staging': 'ayffos6hz',
    'prod':'ayffos6hz'
}

export const CLIENT_ID = {
    'alhnxkfca': 'a1066ba3-7ed8-41e0-a784-30efe31428e2',
    'ayffos6hz':'7ff2f2d6-6a2b-465f-880f-a9112e6a0183'
}

export function getAuthUrl(host){
    return `https://${authoriser_domain[host]}.accounts.ondemand.com`
}