import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Subject } from "rxjs";
import { ApiService } from "src/app/core-module/services/api.service";

@Component({
  selector: "app-feature-non-supported",
  templateUrl: "./feature-non-supported.component.html",
  styleUrls: ["./feature-non-supported.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FeatureNonSupportedComponent implements OnInit {
  customLoader: boolean = false;
  isPageReady: boolean = false;
  setmode: any;
  unsubscribe = new Subject<any>();
  url;
  deviceName: string;
  devicetypePicture: string;
  softwareVersion: string;
  receivedFeature: any;
  currentMode: string;
  pathVal: string;
  constructor(
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.pathVal = this.apiService.getImageUrl(window.location.hostname);
    window.scroll(0, 0);
    this.apiService.getEnvValue();
    this.apiService.removeModes("valid");
    this.setmode = JSON.parse(localStorage.getItem("modes"));
    localStorage.setItem("setmode", JSON.stringify(this.setmode));
    const data = JSON.parse(localStorage.getItem("readerDeviceFullInfo"));
    this.receivedFeature = data[0];
    var domain = "https://www.api.easydesignintool.signify.com";
    this.devicetypePicture = domain + this.receivedFeature.devicetypePicture;
    this.softwareVersion = JSON.parse(
      localStorage.getItem("comparefirmwarenoLatest")
    );
  }
  ngOnDestroy() {
    localStorage.removeItem("setmode");
  }
}
