import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { CustomAuthService } from '../services/custom-auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authSrv: CustomAuthService
    ) { }

    canActivate() {
        if(!this.authSrv.getToken()) this.authSrv.checkForAutoLogin();
        if (this.authSrv.isUserLoggedIn()) {
            return true;
        }
        else{
        this.router.navigate(['/home']);
        return false;
        }
    }
}