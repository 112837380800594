import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthConfig, OAuthService } from "angular-oauth2-oidc";
import { ApiService } from "./api.service";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  authoriser_domain,
  CLIENT_ID,
  getAuthUrl,
} from "src/assets/environment_urls/oAuth_urls";
import { Subject } from "rxjs";
import { Location } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class CustomAuthService {
  constructor(
    private oauthSrv: OAuthService,
    private httpSrv: HttpClient,
    private service: ApiService,
    private modalService: NgbModal,
    private router: Router,
    private location: Location
  ) {}

  private token = null;
  private refreshToken = null;
  private expireTime = null;
  private tokenExpiryCheckInterval;
  private userInfo = null;
  private loggigIn = false;
  private idToken = null;

  public isRefreshing = false;
  public baseUrl = "";

  public onRefreshing = new Subject<boolean>();
  public onLogin = new Subject<boolean>();
  public onLogout = new Subject<boolean>();

  setIdToken(token) {
    this.idToken = token;
  }

  getIdToken() {
    return this.idToken;
  }

  getRedirectUri() {
    return `${window.location.protocol}//${window.location.host}/home`;
  }

  setToken(token) {
    this.token = token;
  }

  getToken() {
    return this.token;
  }

  setRefreshToken(token) {
    this.refreshToken = token;
  }

  getRefreshToken() {
    return this.refreshToken;
  }

  setExpireTime(time) {
    this.expireTime = time;
  }

  getExpireTime() {
    return this.expireTime;
  }

  setUserInfo(info) {
    this.userInfo = info;
  }

  getUserInfo() {
    return this.userInfo;
  }

  getUserName() {
    if (this.userInfo) {
      return this.userInfo["first_name"] + " " + this.userInfo["last_name"];
    }
    return null;
  }

  getClientId() {
    let domain = authoriser_domain[this.getHostName()];
    return CLIENT_ID[domain];
  }

  configureOauth() {
    let config: AuthConfig = {
      issuer: getAuthUrl(this.getHostName()),
      clientId: this.getClientId(),
      redirectUri: this.getRedirectUri(),
      scope: "openid",
      responseType: "code",
      showDebugInformation: true,
      loginUrl: `${getAuthUrl(this.getHostName())}/oauth2/authorize`,
    };
    this.oauthSrv.configure(config);
  }

  checkForAutoLogin() {
    if(this.router.url.includes('fromMyLighting=true')){
      this.login();
      return;
    }
    if (this.token) return;
    let token = localStorage.getItem("access_token");
    if (token) {
      let refreshToken = localStorage.getItem("refresh_token");
      let expireTime = localStorage.getItem("expires_in");
      let userInfo = localStorage.getItem("userInfo");
      if(!userInfo) this.getUserInfoUsingCode();
      let idToken = localStorage.getItem("id_token");
      this.setToken(token);
      this.setRefreshToken(refreshToken);
      this.setExpireTime(expireTime ? JSON.parse(expireTime) : null);
      this.setUserInfo(userInfo ? JSON.parse(userInfo) : null);
      this.setIdToken(idToken);
      this.startTokenExpirationCheck();
    }
  }

  login() {
    this.oauthSrv.initCodeFlow();
  }

  async startTokenGenUsingCode(code) {
    this.setLoggingIn(true);
    await this.getTokenOnLogIn(code);
    this.getUserInfoUsingCode();
    this.setLoggingIn(false);
    this.location.replaceState("/");
    this.router.navigate(["/"], { replaceUrl: true });
  }

  async getTokenOnLogIn(code) {
    let codeVerifier = sessionStorage.getItem("PKCI_verifier");
    let tokenUrl = this.baseUrl + "auth/api/v1/authenticate/code";
    const data = await this.httpSrv
      .post(tokenUrl, {
        code: code,
        code_verifier: codeVerifier,
        redirect_uri: this.getRedirectUri(),
      })
      .toPromise()
      .catch((err) => null);
    if (data) {
      this.setToken(data["access_token"]);
      this.setRefreshToken(data["refresh_token"]);
      this.setIdToken(data["id_token"]);
      this.setExpireTime(Date.now() + data["expires_in"] * 1000);
      localStorage.setItem("access_token", data["access_token"]);
      localStorage.setItem("refresh_token", data["refresh_token"]);
      localStorage.setItem("id_token", data["id_token"]);
      this.startTokenExpirationCheck();
      this.onLogin.next(true);
    }
  }

  getTokenUsingRefreshToken() {
    let refreshurl = this.baseUrl + "auth/api/v1/authenticate/refresh";
    this.httpSrv
      .post(refreshurl, {
        refresh_token: this.refreshToken,
      })
      .subscribe(
        (data) => {
          this.setToken(data["access_token"]);
          this.setRefreshToken(data["refresh_token"]);
          this.setIdToken(data["id_token"]);
          this.setExpireTime(Date.now() + data["expires_in"] * 1000);
          localStorage.setItem("access_token", data["access_token"]);
          localStorage.setItem("refresh_token", data["refresh_token"]);
          localStorage.setItem("id_token", data["id_token"]);
          this.getUserInfoUsingCode();
          this.isRefreshing = false;
          this.onRefreshing.next(true);
        },
        (err) => {
          this.isRefreshing = false;
          this.onRefreshing.next(true);
        }
      );
  }

  async getUserInfoUsingCode() {
    let url = `${getAuthUrl(this.getHostName())}/oauth2/userinfo`;
    try{
      const data = await this.httpSrv.get(url).toPromise();
      if (data) {
        this.userInfo = data;
        this.expireTime = data["exp"] * 1000;
        localStorage.setItem("expires_in", this.expireTime);
        localStorage.setItem("userInfo", JSON.stringify(data));
      }
    }catch{}
    
  }

  startTokenExpirationCheck() {
    this.tokenExpiryCheckInterval = setInterval(() => {
      this.tokenCheck();
    }, 60000);
    this.tokenCheck();
  }

  tokenCheck() {
    if (this.isRefreshing) return;
    if (this.expireTime && this.expireTime < Date.now()) {
      if (document.visibilityState == "visible") this.handleTokenExpiry();
    } else if (this.expireTime && this.expireTime - Date.now() <= 180000) {
      if (document.visibilityState == "visible") {
        this.isRefreshing = true;
        this.getTokenUsingRefreshToken();
      }
    }
  }

  handleTokenExpiry(fromLogout = false) {
    if (fromLogout) this.handleDataOnUserSessionEnd();
    const logourUrl = `${getAuthUrl(this.getHostName())}/oauth2/logout`;
    let redirectUrl = this.getRedirectUri() + "?logout=true";
    if (redirectUrl.includes("localhost")) {
      redirectUrl =
        "https://dev.multione.signify.com/home?redirectToLocal=" +
        this.getRedirectUri();
    }
    this.location.replaceState("/");
    this.onLogout.next(true);
    this.clearTokenCHeck();
    window.location.href = `${logourUrl}?client_id=${this.getClientId()}&id_token_hint=${
      this.idToken
    }&post_logout_redirect_uri=${redirectUrl}`;
  }

  /**Revoke the token if authoriser does not handle the token revocation implicitly */
  // async revokeToken() {
  //   const revoke_url = "https://alhnxkfca.accounts.ondemand.com/oauth2/revoke";
  //   if (this.token) {
  //     const body = new URLSearchParams();
  //     body.set("token", this.token);
  //     body.set("token_type_hint", "access_token");
  //     body.set("client_id", this.clientId);

  //     const headers = {
  //       "content-Type": "application/x-www-form-urlencoded",
  //     };

  //     await this.httpSrv
  //       .post(revoke_url, body.toString(), { headers })
  //       .toPromise();
  //   }
  // }

  clearTokenCHeck() {
    if (this.tokenExpiryCheckInterval) {
      clearInterval(this.tokenExpiryCheckInterval);
    }
  }

  setLoggingIn(value) {
    this.loggigIn = value;
  }

  IsLoggigIn() {
    return this.loggigIn;
  }

  getHostName() {
    let host = window.location.hostname;
    if (host.includes("localhost")) return "localhost";
    if (host.includes("tst")) return "tst";
    if (host.includes("dev")) return "dev";
    if (host.includes("staging")) return "staging";
    if (host.includes("www.multione.signify.com")) return "prod";
  }

  isTokenValid() {
    if (this.token && this.expireTime >= Date.now()) return true;
    return false;
  }

  isUserLoggedIn() {
    return !!(this.token && this.isTokenValid());
  }

  async handleDataOnUserSessionEnd() {
    this.token = null;
    this.expireTime = null;
    this.refreshToken = null;
    this.userInfo = null;
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("expires_in");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("readerDevices");
    localStorage.removeItem("detectedReaderInfo");
    localStorage.removeItem("modes");
    localStorage.removeItem("storeColumn");
    localStorage.removeItem("lastSorting");
    localStorage.removeItem("storeSaveFeatureFile");
    localStorage.removeItem("storeDCFFile");
    this.modalService.dismissAll();
    this.service.sendCloseSelectDevicePopup("close");
    this.service.sendCloseConfigPopup("close");
  }
}
