import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { ApiService } from "src/app/core-module/services/api.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { DataLayerService } from "src/app/core-module/services/data-layer.service";
import jwt_decode from "jwt-decode";
import { CustomAuthService } from "src/app/core-module/services/custom-auth.service";
@Component({
  selector: "app-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.scss"],
})
export class FilterComponent implements OnInit {
  @Output() filterChekced: EventEmitter<any> = new EventEmitter();
  checkedList = [];
  appCount = 0;
  familyCount = 0;
  configCount = 0;
  boxConfigCount = 0;
  regionCount = 0;
  removedValue;
  outdoorValue: boolean;
  linearValue;
  pointerValue;
  oldValue = false;
  countLinear = 1;
  countPoint = 1;
  countOutdoor = 1;
  simpleSetValue;
  daliValue;
  multideviceValue;
  northAmerica;
  otherRegions;
  emea;
  outdoorSr;
  outdoorLed;
  indoorsr;
  indoorLed;
  indoorDownlight;
  xitaniumOutdoor;
  ipRated;
  xitaniumLinear;
  wireless;
  linearFixed;
  xitaniumBasic;
  applicationCounter = 0;
  pathVal: string;
  isReadOnly: boolean = true;
  envDeviceListURL;
  unsubscribe = new Subject<any>();
  fullDeviceList = "search/api/v1/search/v1/devices?showArchive=true";
  preferencesAPI = "config/api/v1/preferences";
  url;
  searchText: any = "";

  constructor(
    private apiService: ApiService,
    private _dataLayerService: DataLayerService,
    private authSrv: CustomAuthService
  ) {}

  ngOnInit() {
    
    this.pathVal = this.apiService.getImageUrl(window.location.hostname);
    this.apiService.setConfig("");
    if(!this.authSrv.isUserLoggedIn()) return;
    this.apiService.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        this.url = url;
        if (url.length) {
          this.envDeviceListURL = url + this.fullDeviceList;
          this.unsubscribe.next();
          this.apiService
            .apiCall("get", url + this.preferencesAPI)
            .subscribe((response) => {
              let filterObj = {};
              if (response["storeFilter"]) {
                filterObj["storeFilterData"] =
                  response["storeFilter"]["storeFilterData"];
              }
              if (response["storeFilter"]) {
                if (response && response["storeFilter"]["storeFilterCheck"]) {
                  filterObj["storeFilterCheck"] =
                    response["storeFilter"]["storeFilterCheck"];
                  localStorage.setItem(
                    "storeFilter",
                    JSON.stringify(filterObj)
                  );
                  for (
                    let i = 0;
                    i < filterObj["storeFilterData"].length;
                    i++
                  ) {
                    let data;
                    let abc = filterObj["storeFilterData"][i].split("=").pop();
                    this.removedValue = abc;
                    if (
                      this.removedValue === "Outdoor" ||
                      this.removedValue === "Indoor Linear" ||
                      this.removedValue === "Indoor Point"
                    ) {
                      data = "application";
                    }
                    if (
                      this.removedValue === "DALI" ||
                      this.removedValue === "SimpleSet" ||
                      this.removedValue ===
                        "SimpleSetBoxConfiguration" ||
                      this.removedValue === "Box configuration"
                    ) {
                      data = "configurationInterface";
                    }
                    if (
                      this.removedValue === "EMEA" ||
                      this.removedValue === "NA" ||
                      this.removedValue === "APR"
                    ) {
                      data = "region";
                    }
                    // if (this.removedValue === `Multi device configuration`) {
                    //   data = "multiDeviceConfiguration";
                    // }
                    if (
                      this.removedValue === "Xitanium Point Dim and Prog" ||
                      this.removedValue === "Xitanium FULL Programmable" ||
                      this.removedValue === "Xitanium LITE Programmable" ||
                      this.removedValue ===
                        "Xitanium Linear DALI Dim and Prog" ||
                      this.removedValue ===
                        "Xitanium Outdoor Connected - Sensor Ready" ||
                      this.removedValue ===
                        "Xitanium Outdoor Connected - Sensor Ready" ||
                      this.removedValue === "IP Rated" ||
                      this.removedValue ===
                        "Xitanium Linear Connected - Sensor Ready" ||
                      this.removedValue === "Connected - Wireless - Mini" ||
                      this.removedValue === "Xitanium Linear Fixed" ||
                      this.removedValue === "Xitanium Basic Programmable"
                    ) {
                      data = "family";
                    }
                    // this.apiService.postCallForUserPreferences();
                    this.filterOnChecked(data, true);
                  }
                } else {
                  filterObj["storeFilterCheck"] = false;
                  filterObj["storeFilterData"] = [];
                  localStorage.setItem(
                    "storeFilter",
                    JSON.stringify(filterObj)
                  );
                  // this.apiService.postCallForUserPreferences();
                }
              }
            });
        }
      });
    this.apiService.filterRemove.subscribe((data) => {
      if (data || data === "default") {
        if (data === "Box configuration") {
          this.removedValue = "SimpleSetBoxConfiguration";
        } else {
          this.removedValue = data;
        }
        if (
          this.removedValue === "Outdoor" ||
          this.removedValue === "Indoor Linear" ||
          this.removedValue === "Indoor Point"
        ) {
          data = "application";
        }
        if (
          this.removedValue === "DALI" ||
          this.removedValue === "SimpleSetBoxConfiguration" ||
          this.removedValue === "SimpleSet"
        ) {
          data = "configurationInterface";
        }
        if (
          this.removedValue === "EMEA" ||
          this.removedValue === "NA" ||
          this.removedValue === "APR"
        ) {
          data = "region";
        }
        if (
          this.removedValue === "Xitanium Point Dim and Prog" ||
          this.removedValue === "Xitanium FULL Programmable" ||
          this.removedValue === "Xitanium LITE Programmable" ||
          this.removedValue === "Xitanium Linear DALI Dim and Prog" ||
          this.removedValue === "Xitanium Outdoor Connected - Sensor Ready" ||
          this.removedValue === "Xitanium Outdoor Connected - Sensor Ready" ||
          this.removedValue === "IP Rated" ||
          this.removedValue === "Xitanium Linear Connected - Sensor Ready" ||
          this.removedValue === "Connected - Wireless - Mini" ||
          this.removedValue === "Xitanium Linear Fixed" ||
          this.removedValue === "Xitanium Basic Programmable"
        ) {
          data = "family";
        }
        this.oldValue = true;
        this.filterOnChecked(data, false);
        this.removedValue = "";
        this.searchText = "";
      }
    });
    this.apiService.getDefaultFilter.subscribe((resp) => {
      if (resp === "default") {
        let fill = JSON.parse(localStorage.getItem("storeFilter") || "{}");
        let obj = {};
        obj["storeFilterCheck"] = fill["storeFilterCheck"];
        obj["storeFilterData"] = this.checkedList;
        // let i = this.checkedList.length;
        let data;
        let i = this.checkedList.length;
        while (i > 0) {
          this.removedValue = this.checkedList[i - 1].split("=").pop();
          if (
            this.removedValue === "Outdoor" ||
            this.removedValue === "Indoor Linear" ||
            this.removedValue === "Indoor Point"
          ) {
            data = "application";
          }
          if (
            this.removedValue === "DALI" ||
            this.removedValue === "SimpleSet" ||
            this.removedValue === "SimpleSetBoxConfiguration"
          ) {
            data = "configurationInterface";
          }
          if (
            this.removedValue === "EMEA" ||
            this.removedValue === "NA" ||
            this.removedValue === "APR"
          ) {
            data = "region";
          }
          if (
            this.removedValue === "Xitanium Point Dim and Prog" ||
            this.removedValue === "Xitanium FULL Programmable" ||
            this.removedValue === "Xitanium LITE Programmable" ||
            this.removedValue === "Xitanium Linear DALI Dim and Prog" ||
            this.removedValue === "Xitanium Outdoor Connected - Sensor Ready" ||
            this.removedValue === "Xitanium Outdoor Connected - Sensor Ready" ||
            this.removedValue === "IP Rated" ||
            this.removedValue === "Xitanium Linear Connected - Sensor Ready" ||
            this.removedValue === "Connected - Wireless - Mini" ||
            this.removedValue === "Xitanium Linear Fixed" ||
            this.removedValue === "Xitanium Basic Programmable"
          ) {
            data = "family";
          }
          this.oldValue = true;
          this.filterOnChecked(data, false);
          i--;
        }
        localStorage.setItem("storeFilter", JSON.stringify(obj));
        this.apiService.postCallForUserPreferences();
      }
    });
  }

  filterOnChecked(filterKey, e) {
    if (filterKey === "application") {
      this.countOutdoor = 0;
      this.countPoint = 0;
      this.countLinear = 0;
      let app = e.target ? e.target.checked : e;
      if (app) {
        this.appCount = this.appCount + 1;
        if (e.target) {
          this.removedValue = e.target.value;
        }
        if (this.removedValue === "Outdoor") {
          this.outdoorValue = true;
          this.applicationCounter = this.applicationCounter + 1;
        }
        if (this.removedValue === "Indoor Linear") {
          this.linearValue = true;
          this.applicationCounter = this.applicationCounter + 1;
        }
        if (this.removedValue === "Indoor Point") {
          this.pointerValue = true;
          this.applicationCounter = this.applicationCounter + 1;
        }
      } else {
        this.appCount = this.appCount - 1;
        if (this.oldValue) {
          if (this.removedValue === "Outdoor") {
            this.outdoorValue = false;
            this.applicationCounter = this.applicationCounter - 1;
          }
          if (this.removedValue === "Indoor Linear") {
            this.linearValue = false;
            this.applicationCounter = this.applicationCounter - 1;
          }
          if (this.removedValue === "Indoor Point") {
            this.pointerValue = false;
            this.applicationCounter = this.applicationCounter - 1;
          }
        } else {
          if (e.target.value === "Outdoor") {
            this.removedValue = "Outdoor";
            this.applicationCounter = this.applicationCounter - 1;
          }
          if (e.target.value === "Indoor Linear") {
            this.removedValue = "Indoor Linear";
            this.applicationCounter = this.applicationCounter - 1;
          }
          if (e.target.value === "Indoor Point") {
            this.removedValue = "Indoor Point";
            this.applicationCounter = this.applicationCounter - 1;
          }
        }
        // let currentUser = JSON.parse(localStorage.getItem("currentUser"));
        // var decodedObj: any = jwt_decode(JSON.stringify(currentUser));
        // this._dataLayerService.logUserDetails({
        //   UserId: localStorage.getItem("UserId"),
        //   companyId: decodedObj.company["companyId"],
        //   companyName: decodedObj.company["companyName"],
        //   userRole: decodedObj.role,
        // });
        this._dataLayerService.resetRegion(this.removedValue);
      }
    }

    // Family Key is not required in latest requirement
    //   if(filterKey === 'family') {
    //     let family =  e.target ?  e.target.checked : e;
    //     if (family) {
    //       this.familyCount= this.familyCount+1;
    //       let b = e.target ? e.target.value : this.removedValue;
    //       if(b) {
    //         this.removedValue = e.target ? e.target.value : this.removedValue;
    //       }
    //       if(this.removedValue === 'Xitanium Point Dim and Prog') {
    //         this.outdoorSr = true;
    //       }
    //       if(this.removedValue === 'Xitanium FULL Programmable') {
    //         this.outdoorLed = true;
    //       }
    //       if(this.removedValue === 'Xitanium LITE Programmable') {
    //         this.indoorLed = true;
    //       }
    //       if(this.removedValue === 'Xitanium Linear DALI Dim and Prog') {
    //         this.indoorsr = true;
    //       }
    //       if(this.removedValue === 'Xitanium Outdoor Connected - Sensor Ready') {
    //         this.xitaniumOutdoor = true;
    //       }
    //       if(this.removedValue === 'IP Rated') {
    //         this.ipRated = true;
    //       }
    //       if(this.removedValue === 'Xitanium Linear Connected - Sensor Ready') {
    //         this.xitaniumLinear = true;
    //       }
    //       if(this.removedValue === 'Connected - Wireless - Mini') {
    //         this.wireless = true;
    //       }
    //       if(this.removedValue === 'Xitanium Linear Fixed') {
    //         this.linearFixed = true;
    //       }
    //       if(this.removedValue === 'Xitanium Basic Programmable') {
    //         this.xitaniumBasic = true;
    //       }
    //     } else {
    //       this.familyCount= this.familyCount-1;
    //       this.removedValue = e.target ? e.target.value : this.removedValue;
    //       // if(this.oldValue) {
    //         if(this.removedValue === 'Xitanium Point Dim and Prog') {
    //           this.outdoorSr = false;
    //         }
    //         if(this.removedValue === 'Xitanium FULL Programmable') {
    //           this.outdoorLed = false;
    //         }
    //         if(this.removedValue === 'Xitanium LITE Programmable') {
    //           this.indoorLed = false;
    //         }
    //         if(this.removedValue === 'Xitanium Linear DALI Dim and Prog') {
    //           this.indoorsr = false;
    //         }
    //         if(this.removedValue === 'Xitanium Outdoor Connected - Sensor Ready') {
    //           this.xitaniumOutdoor = false;
    //         }
    //         if(this.removedValue === 'IP Rated') {
    //           this.ipRated = false;
    //         }
    //         if(this.removedValue === 'Xitanium Linear Connected - Sensor Ready') {
    //           this.xitaniumLinear = false;
    //         }
    //         if(this.removedValue === 'Connected - Wireless - Mini') {
    //           this.wireless = false;
    //         }
    //         if(this.removedValue === 'Xitanium Linear Fixed') {
    //           this.linearFixed = false;
    //         }
    //         if(this.removedValue === 'Xitanium Basic Programmable') {
    //           this.xitaniumBasic = false;
    //         }
    //       // }
    //     }
    //  }
    if (filterKey === "configurationInterface") {
      let config = e.target ? e.target.checked : e;
      if (config) {
        this.configCount = this.configCount + 1;
        let interf = e.target ? e.target.checked : e;
        if (interf) {
          this.removedValue = e.target ? e.target.value : this.removedValue;
        }
        if (this.removedValue === "DALI") {
          this.daliValue = true;
        }
        if (this.removedValue === "SimpleSet") {
          this.simpleSetValue = true;
        }
        if (
          this.removedValue === "SimpleSetBoxConfiguration" ||
          this.removedValue === "Box configuration"
        ) {
          this.multideviceValue = true;
        }
      } else {
        this.configCount = this.configCount - 1;
        this.removedValue = e.target ? e.target.value : this.removedValue;
        // if(this.oldValue) {
        if (this.removedValue === "DALI") {
          this.daliValue = false;
        }
        if (this.removedValue === "SimpleSet") {
          this.simpleSetValue = false;
        }
        if (
          this.removedValue === "SimpleSetBoxConfiguration" ||
          this.removedValue === "Box configuration"
        ) {
          this.multideviceValue = false;
        }
        // let currentUser = JSON.parse(localStorage.getItem("currentUser"));
        // var decodedObj: any = jwt_decode(JSON.stringify(currentUser));
        // this._dataLayerService.logUserDetails({
        //   UserId: localStorage.getItem("UserId"),
        //   companyId: decodedObj.company["companyId"],
        //   companyName: decodedObj.company["companyName"],
        //   userRole: decodedObj.role,
        // });
        this._dataLayerService.resetRegion(this.removedValue);
        // }
      }
    }
    //Multi device configuration
    if (filterKey === "boxConfig") {
      let config = e.target ? e.target.checked : e;
      if (config) {
        this.boxConfigCount = this.boxConfigCount + 1;
        let interf = e.target ? e.target.checked : e;
        if (interf) {
          this.removedValue = e.target ? e.target.value : this.removedValue;
        }
        if (this.removedValue === `SimpleSetBoxConfiguration`) {
          this.multideviceValue = true;
        }
      } else {
        this.boxConfigCount = this.boxConfigCount - 1;
        this.removedValue = e.target ? e.target.value : this.removedValue;
        if (this.removedValue === `SimpleSetBoxConfiguration`) {
          this.multideviceValue = false;
        }
        // let currentUser = JSON.parse(localStorage.getItem("currentUser"));
        // var decodedObj: any = jwt_decode(JSON.stringify(currentUser));
        // this._dataLayerService.logUserDetails({
        //   UserId: localStorage.getItem("UserId"),
        //   companyId: decodedObj.company["companyId"],
        //   companyName: decodedObj.company["companyName"],
        //   userRole: decodedObj.role,
        // });
        this._dataLayerService.resetRegion(this.removedValue);
      }
    }
    if (filterKey === "region") {
      let region = e.target ? e.target.checked : e;
      if (region) {
        this.regionCount = this.regionCount + 1;
        let regionValue = e.target ? e.target.checked : e;
        if (regionValue) {
          this.removedValue = e.target ? e.target.value : this.removedValue;
        }
        if (this.removedValue === "EMEA") {
          this.emea = true;
        }
        if (this.removedValue === "NA") {
          this.northAmerica = true;
        }
        if (this.removedValue === "APR") {
          this.otherRegions = true;
        }
      } else {
        this.regionCount = this.regionCount - 1;
        this.removedValue = e.target ? e.target.value : this.removedValue;
        // if(this.oldValue) {
        if (this.removedValue === "EMEA") {
          this.emea = false;
        }
        if (this.removedValue === "NA") {
          this.northAmerica = false;
        }
        if (this.removedValue === "APR") {
          this.otherRegions = false;
        }
        // let currentUser = JSON.parse(localStorage.getItem("currentUser"));
        // var decodedObj: any = jwt_decode(JSON.stringify(currentUser));
        // this._dataLayerService.logUserDetails({
        //   UserId: localStorage.getItem("UserId"),
        //   companyId: decodedObj.company["companyId"],
        //   companyName: decodedObj.company["companyName"],
        //   userRole: decodedObj.role,
        // });
        this._dataLayerService.resetRegion(this.removedValue);
        // }
      }
    }
    //  if(filterKey === 'region') {
    //    let region = e.target ? e.target.checked : e;
    //   if (region) {
    //     this.regionCount= this.regionCount+1;
    //     if(region) {
    //       this.removedValue = e.target ?  e.target.value : this.removedValue;
    //     }
    //     if(this.removedValue === 'EMEA') {
    //       this.emea = true;
    //     }
    //     if(this.removedValue === 'NA') {
    //       this.northAmerica = true;
    //     }
    //   } else {
    //     this.removedValue = e.target ?  e.target.value : this.removedValue;
    //     this.regionCount= this.regionCount-1;
    //     if(this.removedValue === 'EMEA') {
    //       this.emea = false;
    //     }
    //     if(this.removedValue === 'NA') {
    //       this.northAmerica = false;
    //     }
    //   }
    //  }
    var filterValue;
    // if(filterKey=='region'){
    //   if(this.oldValue) {
    //     filterValue=filterKey+"="+this.removedValue;
    //   } else {
    //     this.removedValue = e.target ?  e.target.value : this.removedValue;
    //     filterValue=filterKey+"="+this.removedValue;
    //   }
    // }else{
    if (filterKey == "region") {
      if (this.oldValue) {
        let checking = this.removedValue;
        filterValue = filterKey + "=" + checking;
      } else {
        let checking = this.removedValue ? this.removedValue : e.target.value;
        filterValue = filterKey + "=" + checking;
      }
    } else {
      if (filterKey == "boxConfig") {
        filterKey = 'configurationInterface';
      };
      if (this.oldValue) {
        let checking = this.removedValue;
        filterValue = filterKey + "[]=" + checking;
      } else {
        let checking = this.removedValue ? this.removedValue : e.target.value;
        filterValue = filterKey + "[]=" + checking;
      }
    }
    let p = e.target ? e.target.checked : e;
    if (p) {
      this.checkedList.push(filterValue);
    } else {
      for (var i = 0; i < this.checkedList.length; i++) {
        if (this.checkedList[i] == filterValue) {
          var str = this.checkedList[i].split("=").pop();
          this.checkedList.splice(i, 1);
        }
      }
    }

    for (var i = 0; i < this.checkedList.length; i++) {
      var str = this.checkedList[i].split("=").pop();
      if (str == "Outdoor") {
        this.countOutdoor = 1;
      }
      if (str == "Indoor Linear") {
        this.countLinear = 1;
      }
      if (str == "Indoor Point") {
        this.countPoint = 1;
      }
    }
    if (this.applicationCounter <= 0) {
      this.countOutdoor = 1;
      this.countLinear = 1;
      this.countPoint = 1;
    }

    if (this.countOutdoor < 1) {
      let outdoorDrivers = [
        "family[]=Xitanium LITE Programmable",
        "family[]=Xitanium FULL Programmable",
      ];
      this.indoorLed = false;
      this.outdoorLed = false;
      this.xitaniumOutdoor = false;
      this.ipRated = false;
      this.xitaniumBasic = false;
      this.checkedList = this.checkedList.filter(
        (item) => item !== "family[]=Xitanium LITE Programmable"
      );
      this.checkedList = this.checkedList.filter(
        (item) => item !== "family[]=Xitanium FULL Programmable"
      );
      this.checkedList = this.checkedList.filter(
        (item) => item !== "family[]=Xitanium Outdoor Connected - Sensor Ready"
      );
      this.checkedList = this.checkedList.filter(
        (item) => item !== "family[]=IP Rated"
      );
      this.checkedList = this.checkedList.filter(
        (item) => item !== "family[]=Xitanium Basic Programmable"
      );
    }
    if (this.countLinear < 1) {
      this.indoorsr = false;
      this.xitaniumLinear = false;
      this.linearFixed = false;
      this.checkedList = this.checkedList.filter(
        (item) => item !== "family[]=Xitanium Linear DALI Dim and Prog"
      );
      this.checkedList = this.checkedList.filter(
        (item) => item !== "family[]=Xitanium Linear Connected - Sensor Ready"
      );
      this.checkedList = this.checkedList.filter(
        (item) => item !== "family[]=Xitanium Linear Fixed"
      );
    }
    if (this.countPoint < 1) {
      this.wireless = false;
      this.outdoorSr = false;
      this.checkedList = this.checkedList.filter(
        (item) => item !== "family[]=Connected - Wireless - Mini"
      );
      this.checkedList = this.checkedList.filter(
        (item) => item !== "family[]=Xitanium Point Dim and Prog"
      );
    }
    this.oldValue = false;
    let fill = JSON.parse(localStorage.getItem("storeFilter") || "{}");
    let obj = {};
    obj["storeFilterCheck"] = fill["storeFilterCheck"];
    obj["storeFilterData"] = this.checkedList;
    localStorage.setItem("storeFilter", JSON.stringify(obj));
    this.apiService.postCallForUserPreferences();
    this.filterChekced.emit(this.checkedList);
  }

  searchDevice(searchText) {
    this.searchText = searchText;
    this.apiService.searchDevice(searchText);
  }

  clearFilter(searchText) {
    this.searchText = "";
    this.apiService.clearFilter(searchText);
  }

  noText(searchText) {
    if (searchText.length < 1) {
      this.clearFilter(searchText);
    }
  }
}
