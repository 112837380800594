import { Component, Inject, NgZone, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { CustomAuthService } from "./core-module/services/custom-auth.service";
import { ApiService } from "./core-module/services/api.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  getCloseHeaderPopUpModalVal: boolean;
  count: number = 0;
  unsubscribe = new Subject<any>();
  constructor(
    private authSrv: CustomAuthService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private _router: Router,
    private zone: NgZone,
    private apiService: ApiService,
    @Inject(DOCUMENT) private document: Document
  ) {
    //translate.setDefaultLang("en");
  }
  title = "MultiOne";
  canShow = true;
  ngOnInit() {
    document.addEventListener('visibilitychange', () => {
      if(document.visibilityState == 'visible'){
        this.authSrv.tokenCheck();
        localStorage.removeItem('lastActive');
      }else{
        this.storeLastVisible();
      }
    })
    this.canShow = false;
    this.apiService.getEnvValue();
    this.apiService.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length) {
          this.unsubscribe.next();
          this.authSrv.baseUrl = url as any;
          this.authSrv.configureOauth();
          if(!this._router.url.includes("logout=true") && !this._router.url.includes("redirectToLocal")) this.authSrv.checkForAutoLogin();
          this.canShow = true;
        }
      });

    this.route.queryParams.subscribe((params) => {
      if (params["lang"]) {
        var lang = params["lang"];
      }
      if (lang == "nl") {
        this.translate.use("nl");
      }
    });
  }

  storeLastVisible(){
    localStorage.setItem('lastActive', JSON.stringify(Date.now()))
  }
}
