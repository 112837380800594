import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnDestroy,
} from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { ApiService } from "src/app/core-module/services/api.service";
import { isNullOrUndefined } from "util";
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";

const customMinMaxValidator = (propsDetails, prop) => {
  return (control: FormControl) => {
    const propData = propsDetails[prop];
    const value = control.value;
    const min = propData.min;
    const max = propData.max;
    if (value < min || value > max) return { error: true };
    return null;
  };
};
const DIM_TO_OFF_LABEL = {
  "0.5V": "0.5V dim to off",
  "1.0V": "1.0V dim to off (for traditional dimmer)",
};

const data_types = {
  ZtvCurveSelection: "text",
  ZtvMinDimLevel: "number",
  ZtvEnabled: "boolean",
  ZtvDimToOffEnabled: "boolean",
  FadeOnTime: "number",
  FadeOffTime: "number",
  FastFadeEnabled: "boolean",
  DimToOffThreshold: "text",
  ZtvMinDimCurrent: "number",
  MinDimOption:'number'
};

@Component({
  selector: "app-ztv",
  templateUrl: "./ztv.component.html",
  styleUrls: ["./ztv.component.scss"],
})
export class ZtvComponent extends FieldType implements OnInit, OnDestroy {
  @Input() field;
  propertiesDetails;
  propertiesInfo = {};
  ztvFormGroup = new FormGroup({});
  valueChangesSubs = {};
  quickConfigvalue: boolean;
  constructor(private service: ApiService) {
    super();
  }

  ngOnInit() {
    this.service.getquickConfig.subscribe((data) => {
      if (data === "quickConfig") {
        this.quickConfigvalue = true;
      } else if (data === "standard") {
        this.quickConfigvalue = false;
      }
    });

    this.service.getztvRestoreBtnClick.subscribe((res) => {
      if (res == "clicked") {
        this.resetDefault();
      }
    });

    if (this.field) {
      this.propertiesDetails = this.field.properties;
      this.buildInitialData();
      this.buildFormGroup();
      for (let key in this.ztvFormGroup.controls) {
        this.syncFromValueChanges(key);
      }
      this.saveValueOnChange();
    }
  }

  buildInitialData() {
    let localData = this.getDataFromLocal();
    if (
      !localData ||
      localData["fromDevice"] ||
      Object.keys(localData).length == 0
    ) {
      this.preparePropertiesInfo(localData);
    } else {
      this.propertiesInfo = { ...localData };
    }
  }

  buildFormGroup() {
    for (let key in this.propertiesInfo) {
      const control = new FormControl(this.propertiesInfo[key]["value"], [
        customMinMaxValidator(this.propertiesInfo, key),
      ]);
      this.ztvFormGroup.addControl(key, control);
      if (
        this.propertiesInfo[key]["disabled"] ||
        !this.propertiesInfo[key]["useProp"]
      )
        this.disableControl(control, key);
      else this.updateOnlyValidity(control, key);
    }
  }

  syncFromValueChanges(key) {
    const control = this.ztvFormGroup.get(key);
    let propInfo = this.propertiesInfo[key];
    this.valueChangesSubs[key] = control.valueChanges.subscribe((res) => {
      propInfo["value"] = control.value;
      propInfo["error"] = control.invalid;
      propInfo["enumItems"].forEach(
        (item) => (item["selected"] = item.value == propInfo["value"])
      );
      if (key == "ZtvCurveSelection") {
        let mindimInfo = this.propertiesInfo["ZtvMinDimLevel"];
        if (control.value != "0-10V linear" && control.value != "0-10V logarithmic") {
          if (mindimInfo) {
            if (control.invalid) {
              this.ztvFormGroup.patchValue({
                ZtvMinDimLevel: mindimInfo["default"],
              });
            }
            mindimInfo["showPropOnUi"] = false;
          }
        } else {
          if (mindimInfo) {
            mindimInfo["showPropOnUi"] = true;
          }
        }
      }
      this.saveValueOnChange();
    });
  }

  onSliderValueChange(value, property) {
    let formvalue = {};
    formvalue[property] = value;
    this.ztvFormGroup.patchValue({
      ...formvalue,
    });
  }

  onCheckboxChange(prop, value, checked) {
    const propInfo = this.propertiesInfo[prop];
    let dimtooffdependentProps = [
      "DimToOffThreshold",
      "FadeOnTime",
      "FadeOffTime",
    ];
    let fastFadeDependProps = ["FadeOnTime", "FadeOffTime"];
    if (!propInfo) return;

    if (prop == "ZtvEnabled") {
      this.ztvFormGroup.patchValue(
        {
          ZtvEnabled: checked,
        },
        { emitEvent: false }
      );
      propInfo["value"] = checked;
      for (let key in this.propertiesInfo) {
        if (key != "ZtvEnabled") {
          const control = this.ztvFormGroup.get(key);
          const info = this.propertiesInfo[key];
          info["disabled"] = !checked;
          if (dimtooffdependentProps.includes(key)) {
            const dimtooffcontrol = this.ztvFormGroup.get("ZtvDimToOffEnabled");
            if (dimtooffcontrol) {
              info["disabled"] = info["disabled"] || !dimtooffcontrol.value;
            }
          }
          if (fastFadeDependProps.includes(key)) {
            const fastfadecontrol = this.ztvFormGroup.get("FastFadeEnabled");
            if (fastfadecontrol) {
              info["disabled"] = info["disabled"] || fastfadecontrol.value;
            }
          }
          if (info["disabled"] || !info["useProp"]) {
            this.disableControl(control, key);
          } else {
            this.enableControl(control, key);
          }
        }
      }
    } else if (prop == "ZtvDimToOffEnabled" || prop == "FastFadeEnabled") {
      let valueObj = {};
      valueObj[prop] = checked;
      this.ztvFormGroup.patchValue(valueObj, { emitEvent: false });
      propInfo["value"] = checked;

      let fastfade = this.propertiesInfo["FastFadeEnabled"];
      let dimtooffEnable = this.propertiesInfo["ZtvDimToOffEnabled"];
      dimtooffdependentProps.forEach((dimtooffdependentprop) => {
        const info = this.propertiesInfo[dimtooffdependentprop];
        if (info) {
          const control = this.ztvFormGroup.get(dimtooffdependentprop);
          if (dimtooffEnable) info["disabled"] = !dimtooffEnable.value;
          if (fastFadeDependProps.includes(dimtooffdependentprop) && fastfade) {
            info["disabled"] = info["disabled"] || fastfade.value;
          }
          if (info["disabled"] || !info["useProp"]) {
            this.disableControl(control, dimtooffdependentprop);
          } else {
            this.enableControl(control, dimtooffdependentprop);
          }
        }
      });
    } else if (prop == "DimToOffThreshold") {
      let enumItems = propInfo["enumItems"];
      enumItems.forEach((item) => {
        item["selected"] =
          (item["value"] == value && checked) ||
          (item["value"] !== value && !checked)
            ? true
            : false;
        if (item["selected"]) {
          propInfo["value"] = item["value"];
        }
      });
    } else {
      if (propInfo["hasOneOf"]) {
        propInfo["disablepropOnUse"].forEach((dependProp) => {
          const depcontrol = this.ztvFormGroup.get(dependProp);
          const depPropInfo = this.propertiesInfo[dependProp];
          if (depcontrol) {
            depPropInfo["useProp"] = !checked;
            if (checked) this.disableControl(depcontrol, dependProp);
            else this.enableControl(depcontrol, dependProp);
          }
        });
        const control = this.ztvFormGroup.get(prop);
        propInfo["useProp"] = checked;
        if (control) {
          if (!checked) this.disableControl(control, prop);
          else this.enableControl(control, prop);
        }
        if(prop == 'ZtvMinDimLevel' || prop == 'ZtvMinDimCurrent'){
          let mindimoption = this.propertiesInfo['MinDimOption'];
          if(mindimoption) mindimoption['value'] = (prop == 'ZtvMinDimLevel' && checked) ||  (prop == 'ZtvMinDimCurrent' && !checked)? 0 : 1;
        }
      }
    }
    this.saveValueOnChange();
  }

  disableControl(control: AbstractControl, controlname) {
    this.updateOnlyValidity(control, controlname);
    control.disable({ emitEvent: false });
  }

  enableControl(control: AbstractControl, controlname) {
    control.enable({ emitEvent: false });
    this.updateOnlyValidity(control, controlname);
  }

  updateOnlyValidity(control: AbstractControl, controlName) {
    const info = this.propertiesInfo[controlName];
    const isdisabled = control.disabled;
    if (isdisabled) control.enable({ emitEvent: false });
    if (this.valueChangesSubs[controlName]) {
      this.valueChangesSubs[controlName].unsubscribe();
      delete this.valueChangesSubs[controlName];
      control.updateValueAndValidity();
      this.syncFromValueChanges(controlName);
    } else {
      control.updateValueAndValidity();
    }
    if (info) info["error"] = control.invalid;
    if (isdisabled) control.disable({ emitEvent: false });
  }

  preparePropertiesInfo(propOptions = null) {
    const propOptionKeys = propOptions ? Object.keys(propOptions) : [];
    for (let key in this.propertiesDetails) {
      if (
        this.isPropertyAvailableInOneOf(key) ||
        this.isPropertyAvailableInRequriredProps(key) || key == 'MinDimOption'
      ) {
        let propDetails = this.propertiesDetails[key];
        let info = {};
        info["default"] = this.getDefault(propDetails, key);
        info["min"] = propDetails["minimum"];
        info["max"] = propDetails["maximum"];
        info["sliderMax"] = propDetails["maximum"];
        info["sliderMin"] = propDetails["minimum"];
        info["options"] = {
          floor: propDetails["minimum"],
          ceil: propDetails["maximum"],
          minLimit: propDetails["minimum"],
          maxLimit: propDetails["maximum"],
        };
        info["value"] = info["default"];
        if (propOptions && propOptionKeys.includes(key)) {
          info = {
            ...info,
            value: key == 'MinDimOption' ? this.getInitialMindimOption(propOptions, propOptionKeys) : propOptions[key],
          };
        }
        
        this.propertiesInfo[key] = info;
      }
    }
    for (let key in this.propertiesDetails) {
      if (
        this.isPropertyAvailableInOneOf(key) ||
        this.isPropertyAvailableInRequriredProps(key) || key == 'MinDimOption'
      ) {
        let propDetails = this.propertiesDetails[key];
        let info = this.propertiesInfo[key];
        info["disabled"] = this.checkForDefaultDisable(key);
        info["showPropOnUi"] = this.checkForDefaultShow(key);
        info["error"] = false;
        info["hasOneOf"] = this.isPropertyAvailableInOneOf(key);
        info["disablepropOnUse"] = this.disableOneOfOnUse(key);
        info["useProp"] = this.canUseProp(
          key,
          info["hasOneOf"],
          info["disablepropOnUse"]
        );
        info["enumItems"] = this.prepareEnumItems(
          propDetails,
          key,
          info["value"]
        );
      }
    }
  }

  getInitialMindimOption(data, keys){
    if(keys.includes("MinDimOption")) return data['MinDimOption'];
    if(keys.includes('ZtvMinDimLevel')) return 0;
    return 1;
  }

  checkForDefaultShow(prop){
    if(prop == 'ZtvMinDimLevel'){
      if(this.isPropertyAvailableInOneOf("ZtvCurveSelection") || this.isPropertyAvailableInRequriredProps("ZtvCurveSelection")){
        let curveSelection = this.propertiesInfo['ZtvCurveSelection'].value;
        if(curveSelection != "0-10V linear" && curveSelection != "0-10V logarithmic") return false;
      } 
    }
    return true;
  }

  checkForDefaultDisable(prop) {
    if (
      this.isPropertyAvailableInOneOf("ZtvEnabled") ||
      this.isPropertyAvailableInRequriredProps("ZtvEnabled")
    ) {
      const ztvenabledValue = this.propertiesInfo['ZtvEnabled'].value;
      if (!ztvenabledValue) return true;
    }
    let dimtooffdependentProps = [
      "DimToOffThreshold",
      "FadeOnTime",
      "FadeOffTime",
    ];
    let fastFadeDependProps = ["FadeOnTime", "FadeOffTime"];
    if (
      dimtooffdependentProps.includes(prop) &&
      (this.isPropertyAvailableInOneOf("ZtvDimToOffEnabled") ||
        this.isPropertyAvailableInRequriredProps("ZtvDimToOffEnabled"))
    ) {
      const dimtooffValue = this.propertiesInfo['ZtvDimToOffEnabled'].value;
      if (!dimtooffValue) return true;
    }
    if (
      fastFadeDependProps.includes(prop) &&
      (this.isPropertyAvailableInOneOf("FastFadeEnabled") ||
        this.isPropertyAvailableInRequriredProps("FastFadeEnabled"))
    ) {
      const fastFadeValue = this.propertiesInfo['FastFadeEnabled'].value;
      if (fastFadeValue) return true;
    }
    return false;
  }

  getDefault(propDetails, prop?) {
    const propdetailsKeys = Object.keys(propDetails);
    let defaultValue = null;
    if (propdetailsKeys.includes("default")) defaultValue = propDetails["default"];
    if (propdetailsKeys.includes("propertyDefault"))
      defaultValue = propDetails["propertyDefault"];
    if(prop == 'ZtvCurveSelection' && !isNaN(defaultValue)){
      let propEnum = propDetails.enum;
      for(let i = 0; i<propEnum.length; i++){
        if(propEnum[i] == defaultValue) {
          defaultValue = propEnum[i+1];
          break;
        }
      }
    }
    return defaultValue;
  }

  prepareEnumItems(propDetails, prop, defaultValue) {
    let items = [];
    if (prop == "ZtvCurveSelection") {
      for (let i = 0; i < propDetails["enum"].length; i++) {
        if(isNaN(propDetails["enum"][i])){
          items.push({
            label: propDetails["enum"][i],
            value: propDetails["enum"][i],
            selected: propDetails["enum"][i] == defaultValue,
          });
        }
      }
    }
    if (prop == "FadeOffTime" || prop == "FadeOnTime") {
      let min = propDetails["minimum"];
      let max = propDetails["maximum"];
      let multiplier = propDetails["multipleOf"];
      for (let i = min; i <= max; i += multiplier) {
        items.push({
          label: i,
          value: i,
          selected: i == defaultValue,
        });
      }
    }
    if (prop == "DimToOffThreshold") {
      propDetails["enum"].forEach((val) => {
        items.push({
          value: val,
          label: DIM_TO_OFF_LABEL[val],
          selected: val == defaultValue,
        });
      });
    }
    return items;
  }

  canUseProp(prop, isoneof, disableProps) {
    if (isoneof) {
      if(prop == 'ZtvMinDimLevel' || prop == 'ZtvMinDimCurrent'){
        let mindimOption = this.propertiesInfo['MinDimOption'].value;
        return (prop == 'ZtvMinDimLevel' && mindimOption == 0) ||  (prop == 'ZtvMinDimCurrent' && mindimOption == 1) ? true : false;
      }
    }
    return true;
  }

  isPropertyAvailableInRequriredProps(prop) {
    let reqProps = this.field.requiredProperties;
    return reqProps.includes(prop);
  }

  isPropertyAvailableInOneOf(prop) {
    let oneOf = this.field.oneOf;
    if (oneOf) {
      return !!oneOf.find((ele) => ele["dependencies"][prop]);
    }
    return false;
  }

  disableOneOfOnUse(prop) {
    if (this.isPropertyAvailableInOneOf(prop)) {
      let oneOf = this.field.oneOf;
      let dependencies = oneOf.find((ele) => ele["dependencies"][prop]);
      if (dependencies) return dependencies["dependencies"][prop];
    }
    return [];
  }

  getDataFromLocal() {
    let data = JSON.parse(localStorage.getItem("configurationData"));
    if (data && !isNullOrUndefined(data["resp"])) {
      if (data["resp"]["ztvObj"]) {
        let ztvOvj = data["resp"]["ztvObj"];
        if (Object.keys(ztvOvj).length > 0) return ztvOvj;
      }
      if (data["resp"]["ZTV"]) {
        let localData = data["resp"]["ZTV"];
        localData["fromDevice"] = true;
        return localData;
      }
    }
    return null;
  }

  setDataToLocal() {
    let data = JSON.parse(localStorage.getItem("configurationData"));
    if (data && !isNullOrUndefined(data["resp"])) {
      data["resp"]["ztvObj"] = { ...this.propertiesInfo };
    } else {
      data["resp"] = { ztvObj: this.propertiesInfo };
    }
    localStorage.setItem("configurationData", JSON.stringify(data));
  }

  checkDefault() {
    for (let key in this.propertiesInfo) {
      const info = this.propertiesInfo[key];
      if (info["value"] != info["default"]) return false;
      if (
        info["useProp"] !=
        this.canUseProp(key, info["hasOneOf"], info["disablepropOnUse"])
      )
        return false;
    }
    return true;
  }

  resetDefault() {
    let formData = {};
    for (let key in this.propertiesInfo) {
      const info = this.propertiesInfo[key];
      info["value"] = info["default"];
    }
    for (let key in this.propertiesInfo) {
      const info = this.propertiesInfo[key];
      info["useProp"] = this.canUseProp(
        key,
        info["hasOneOf"],
        info["disablepropOnUse"]
      );
      info["disabled"] = this.checkForDefaultDisable(key);
      info["error"] = false;
      info["showPropOnUi"] = this.checkForDefaultShow(key);
      if (info["enumItems"]) {
        info["enumItems"].forEach((item) => {
          item["selected"] = item["value"] == info["default"];
        });
      }
      const control = this.ztvFormGroup.get(key);
      if (control) {
        formData[key] = info["value"];
        if (info["disabled"] || !info["useProp"])
          this.disableControl(control, key);
        else this.enableControl(control, key);
      }
    }
    this.ztvFormGroup.patchValue(
      {
        ...formData,
      },
      { emitEvent: false }
    );
    this.saveValueOnChange();
  }

  isFormHasError() {
    for (let key in this.propertiesInfo) {
      let info = this.propertiesInfo[key];
      if (info["error"]) return true;
    }
    return false;
  }

  saveValueOnChange() {
    this.setDataToLocal();
    this.service.ztvEditedVal(this.checkDefault());
    this.sendZTVData();
    this.service.ztvInputError(this.isFormHasError());
  }

  sendZTVData() {
    let data = {};
    for (let key in this.propertiesInfo) {
      let info = this.propertiesInfo[key];
      if (info["useProp"]) {
        if (data_types[key] == "number") data[key] = Number(info["value"]);
        else data[key] = info["value"];
      }
    }
    this.service.sendZtvObj(data);
  }

  featureHasProperty(prop) {
    if (this.propertiesInfo[prop] && this.propertiesInfo[prop]["showPropOnUi"])
      return true;
    return false;
  }

  ngOnDestroy() {}
}
